<template>
  <div class="recommend">
      <div class="logo"><img src="~@assets/image/recommend_logo.png" alt=""></div>
      <div class="content">
          <div class="header">
            <div class="icon"></div>
            <div class="title">互赠规则</div>
            <div class="icon right"></div>
          </div>
          <div class="text">
              <p>1.一年内好友购买VIP/SVIP年卡，双方各赠送“60天时间”; </p>
              <p>2.一年内好友购买VIP/SVIP月卡，双方各赠送“5天”;</p>
              <p>3.一年内好友购买VIP/SVIP连续包月卡，双方每月赠送“6天”; </p>
              <p>4.活动时间：2021年7月1日——2022年7月21日。</p>
          </div>
      </div>
      <div class="btn" >
        <button @click="handleshare">分享7天SVIP体验卡</button>
      </div>
  </div>
</template>

<script>
import {shardGiftCard} from "../api/user"
  export default {
     data(){
      return{
         shareId:""
      }
     },
     methods:{
        //分享
        async handleshare(){
          let userInfo = localStorage.getItem("userInfo")
          //判断是否是登录状态
          if(userInfo == null || userInfo == undefined || userInfo == ""){
             this.$toast("请先登录后，再分享！")
             sessionStorage.setItem("InfoType",2)
             this.$router.push({
               name:'login'
             })
          }else{
            const res = await shardGiftCard()
            if(res.code === 0 ){
              this.shareId = res.data
              let txt = `写手智能科技致力于提供智能规范的内容创作与流程管理服务，现送您SVIP体验卡，赶快来体验吧！- 写手智能科技 http://${window.location.host}/#/share?id=${this.shareId}`
              if(window.android){
                    const obj = {
                      action:'share',
                      data:{
                        type:4,
                        content:txt,
                      }
                  }
                  window.android.postMessage(JSON.stringify(obj));
              }else if(window.webkit){
                // ios 环境下
                const obj = {
                  action: "share",
                  data: {
                    type: 4,
                    content:txt,
                  },
                };
                let jsonStr = JSON.stringify(obj);
                window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
              }
            }
          }
        },
     }
  }
</script>

<style lang="scss" scoped>
.recommend{
   height: 995px;
   background: url("~@assets/image/recommend_background.png") no-repeat;
   background-size: 100% 100%;
   .logo{
     width: 89px;
     height: 24.5px;
     img{
        width: 100%;
        height: 100%;
        margin-top: 25px;
        margin-left: 20px;
     }
   }
   .content{
     margin-left: 20px;
     margin-right: 20px;
     margin-top: 484px;
     z-index: 1;
     height: 380px;
     background: #25243B;
     box-shadow: 1px 1px 13px 0px rgba(32, 24, 48, 0.91);
     border-radius: 20px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     .header{
       font-size: 24px;
       font-family: PingFang SC;
       font-weight: bold;
       color: #FFECCE;
       display: flex;
       justify-content: center;
       align-items: center;
       .icon{
         width: 25px;
         height: 12px;
         background: url("~@assets/image/recommend_icon.png") no-repeat;
         background-size: 100% 100%;
       }
       .right{
         transform: rotateY(180deg);
       }
       .title{
         margin-left: 13.5px;
         margin-right: 13.5px;
       }
     }
     .text{
       width: 304px;
       font-size: 15px;
       font-family: PingFang SC;
       font-weight: bold;
       color: #FFECCE;
       p{
         margin-top: 30px;
       }
     }
   }
   .btn{
     width: 100%;
     text-align: center;
     button{
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #302B33;
      background: linear-gradient(36deg, #E1C392, #FFECCD, #E4C89B);
      width: 240px;
      height: 40px;
      border-radius: 40px;
      text-align: center;
      line-height: 40px;
     }
   }
}
</style>